import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import AddAddressForm from "../../_container/User/AddAddressForm";
import { checkoutStore } from "../../_redux/actions";
import NewFooter from "../homeComponents/footerSection/NewFooter";
import Header from "../homeComponents/headerSection/Header";
import checked from "./images/checked.svg";
import {
  createCheckoutSession,
  ownerRegisteredAddresses,
  placeOrder,
} from "../../services/api/owner.service";
import {
  extractImageUrlsFromPicOfWork,
  formatPrice,
  getFullAddress,
  getMaterialTaxFor,
  parseJwt,
} from "../../services/utils/utilFunctions";
import IMAGES from "../../_assets/images";
import Info from "./images/Info.png";
import Star from "./images/Star.svg";

import { useHistory } from "react-router-dom";
import {
  getContractor,
  getRatings,
} from "../../services/api/contractor.service";
import { selectedCategory } from "../../recoil/atoms/categoryAtom";
import CustomDrawer from "./CustomDrawer";
import ConfirmPageImageGrid from "./ConfirmPageImageGrid";
import { Modal } from "@mui/material";
import Carousel from "./custom-carousel/CustomCarousel";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { connect, useSelector } from "react-redux";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import useNotify from "../../_components/Notification/UseNotify";
import Loader from "../../_components/spinner/Loader";
import { Delete } from "@material-ui/icons";
import axiosInstance from "../../services/api/config/api.config";

const ConfirmContractor = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { confirm } = useNotify();
  const [selectedCategoryState, setSelectedCategoryState] =
    useRecoilState(selectedCategory);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [contractorDetails, updateContractorDetails] = useState({});
  const [imageGrid, setImageGrid] = useState();
  const [sliderOpen, setSliderOpen] = useState(false);
  const [currentActive, setCurrentActive] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addAddressModal, setAddaddressModal] = useState(false);
  const [addressDetails, setAddressDetails] = useState(false);
  const [selectedServicePayload, setSelectedServicePayload] = useState("");
  const [addressLoader, setAddressLoader] = useState(false);
  const imageRefs = useRef([]);

  const {
    ratingInfo,
    managerExperience,
    profileImage,
    highlight,
    onChatClick,
    services,
    contractorId,
  } = contractorDetails;
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const formateData = () => {
    const currentFlow = props.checkout.payload.selectedService;
    let servicePayload;
    switch (currentFlow) {
      case "SolidHardwood":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Engineered":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Carpet":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Tiles":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Epoxy":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Laminated":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Other":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Basement":
        servicePayload = props.buttonReducer.basementPayload;
        break;
      case "Wall opening":
        servicePayload = props.buttonReducer.wallPayload;
        break;
      case "Kitchen Remodeling":
        servicePayload = props.buttonReducer.kitchenPayload;
        break;
      case "deck":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "fence":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "interior":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "exterior":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "Bathroom renovation":
        servicePayload = props.buttonReducer.bathroomPayload;
        break;
      case "HVAC":
        servicePayload = props.buttonReducer.hvacPayload;
        break;
      case "Plumbing":
        servicePayload = props.buttonReducer.plumbingPayload;
        break;
      case "Roofing":
        servicePayload = props.buttonReducer.roofingPayload;
        break;
      case "Windows":
        servicePayload = props.buttonReducer.windowsPayload;
        break;
      case "Electric":
        servicePayload = props.buttonReducer.electricPayload;
        break;
      case "Tree services":
        servicePayload = props.buttonReducer.treePayload;
        break;
      default:
        break;
    }
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);

    servicePayload.personalInfo.name = userDetails.name;
    servicePayload.personalInfo.email = userDetails.email;
    servicePayload.personalInfo.fullAddress = getFullAddress(
      addresses.filter((address) => address._id === selectedAddress?.id)[0]
    );

    const serviceTemp = {
      id: "project-" + new Date().getTime(),
      flow: currentFlow,
      payload: servicePayload,
      projectStartDate: props.checkout.payload.projectStartDate,
      projectStartTime: props.checkout.payload.projectStartTime,
      selectedAddressId: selectedAddress?.id,
      selectedContractorId: contractorId?.id,
    };
    const projects =
      props.checkout.payload && props.checkout.payload.projects
        ? props.checkout.payload.projects
        : [];
    projects.push(serviceTemp);
    const checkoutModel = props.checkout.payload;
    checkoutModel.projects = projects;
    checkoutModel.selectedAddressId = selectedAddress?.id;
    props.checkoutStore(checkoutModel);

    return servicePayload;
  };

  const getOwnerAddresses = (userDetails) => {
    ownerRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        const result = data.data.address;
        setAddresses(result);
        setSelectedAddress(result[0]);
        setAddressLoader(false);
      }
    });
  };

  const handleDeleteAddress = async (id) => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    console.log(userDetails);
    try {
      // this.setState({ isLoading: true });
      await axiosInstance.delete(`/owner/${userDetails._id}/address`, {
        data: {
          addressId: id,
        },
      });
      getUserInfo();
    } catch (err) {
      alert("Failed to delete address");
    } finally {
      // this.setState({ isLoading: false });
    }
  };

  const getRatingCount = (rating) => {
    const count =
      parseInt(rating?.rating1 || 0) +
      parseInt(rating?.rating2 || 0) +
      parseInt(rating?.rating3 || 0) +
      parseInt(rating?.rating4 || 0) +
      parseInt(rating?.rating5 || 0);
    return count;
  };

  const getUserInfo = () => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails?.userType === "owner") {
      // setAddressLoader(true);
      getOwnerAddresses(userDetails);
    }
  };

  const handleEdit = (e, address) => {
    e.preventDefault();
    // setModalTrueset
    setAddaddressModal(true);
    setAddressDetails(address);
  };
  useEffect(() => {
    getUserInfo();
  }, [addressLoader]);

  const handleViewMore = (e) => {
    e.stopPropagation();
    setSliderOpen(true);
    setCurrentActive(0);
  };

  const openSlider = (index) => {
    setCurrentActive(index);
    setSliderOpen(true);
  };

  const getReviews = (contractorId) => {
    // getRatings(contractorId).then((response) => {
    //   setReviewsArray(response?.data?.data);
    // });
  };

  const handleIndex = (index) => {
    setCurrentActive(index);
    setDrawerOpen(true);
  };

  function getImageGrid(imageUrls) {
    const items = [];

    for (let i = 0; i < imageUrls?.length; i += 3) {
      const item1 = (
        <div key={i} className="image-grid__item--full">
          <img
            onClick={() => openSlider(i)}
            src={imageUrls[i]}
            alt={`Image ${i + 1}`}
            ref={(el) => (imageRefs.current[i] = el)}
            id={i}
          />
        </div>
      );

      let item2 = null;
      let item3 = null;

      if (i + 1 < imageUrls.length) {
        item2 = (
          <div key={i + 1} className="image-grid__item--half">
            <img
              onClick={() => openSlider(i + 1)}
              src={imageUrls[i + 1]}
              alt={`Image ${i + 2}`}
              ref={(el) => (imageRefs.current[i + 1] = el)}
              id={i + 1}
            />
          </div>
        );
      }

      if (i + 2 < imageUrls.length) {
        item3 = (
          <div key={i + 2} className="image-grid__item--half">
            <img
              onClick={() => openSlider(i + 2)}
              src={imageUrls[i + 2]}
              alt={`Image ${i + 3}`}
              ref={(el) => (imageRefs.current[i + 2] = el)}
              id={i + 2}
            />
          </div>
        );
      }

      const row = (
        <div key={i / 3} className="image-grid__row">
          {item1}
          <div className="image-grid__row-half">
            {item2}
            {item3}
          </div>
        </div>
      );

      items.push(row);
    }

    setImageGrid(items);
  }

  const handleSubmit = async (e, stripe) => {
    e.preventDefault();
    const servicePayload = formateData();
    setSelectedServicePayload(servicePayload);

    servicePayload.selectedAddressId = selectedAddress?.id;
    const tax = getMaterialTaxFor(selectedServicePayload);
    const orderTotal = parseFloat(props?.checkout?.payload?.totalCost);

    servicePayload.orderAmount = orderTotal;
    servicePayload.tax = tax;
    const totalAmount = orderTotal + tax;

    const postData = {
      number: 0,
      ownerAddress: selectedAddress,
      contractorId: contractorId?._id,
      tentativeStartDate: props?.checkout.payload?.projectStartDate,
      tentativeTimeRange: props?.checkout.payload?.projectStartTime,
      price: totalAmount,
      serviceDetails: {
        categoryId: selectedCategoryState?.id,
        subCategoryId: props?.checkout?.payload?.selectedServiceDetails?._id,
        data: servicePayload,
        address: selectedAddress,
      },
    };

    const line_items = [
      {
        quantity: 1,
        price_data: {
          currency: "usd",
          unit_amount: 9900, // need to change to orginal price
          product_data: {
            name: "Pay now",
            description:
              "Pay only $99 deposit to secure your project, the rest will be charged upon percentage of completion.",
            images: [],
          },
        },
      },
    ];

    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user && user?._id) {
      setIsLoading(true);
      try {
        localStorage.setItem("orderData", JSON.stringify(postData));
        const {
          data: { sessionId },
        } = await createCheckoutSession(user._id, {
          line_items,
          customer_email: user.email,
        });
        const { error } = stripe.redirectToCheckout({ sessionId });
        if (error) console.log(error);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        confirm("error", "An error occured, unable to create session");
        console.log("An error occured, unable to create session", error);
      }
    }
  };

  const onSubmitFormLater = () => {
    const servicePayload = formateData();
    setSelectedServicePayload(servicePayload);

    const user = parseJwt(localStorage.getItem("auth_token"));
    servicePayload.selectedAddressId = selectedAddress?.id;
    const tax = getMaterialTaxFor(servicePayload);
    const orderTotal = parseFloat(props.checkout.payload.totalCost);

    servicePayload.orderAmount = orderTotal;
    servicePayload.tax = tax;
    const totalAmount = orderTotal + tax;

    const postData = {
      number: 0,
      isNotReady: true,
      ownerAddress:
        selectedAddress && selectedAddress.fullAddress
          ? getFullAddress(selectedAddress)
          : "",
      contractorId: contractorId?.id,
      tentativeStartDate: props.checkout.payload.projectStartDate,
      tentativeTimeRange: props.checkout.payload.projectStartTime,
      price: totalAmount,
      serviceDetails: {
        categoryId: props.checkout.payload.selectedServiceDetails.categoryId,
        subCategoryId: props.checkout.payload.selectedServiceDetails._id,
        data: servicePayload,
        address: selectedAddress,
      },
    };

    placeOrder(user._id, postData, user.userType).then(() => {
      props.history.push(`/checkout/order-received/later`);
    });
  };

  useEffect(() => {
    function handleResize() {
      setViewportWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const id = pathArray[2];

    if (!isNaN(parseInt(id))) {
      // toggleLoading(true);
      getContractor(id).then((contractor) => {
        if (contractor.data) {
          // toggleLoading(false);
          let _contractor = { ...contractor.data };
          const images = [];
          if (_contractor.picturesOfWork.length > 0) {
            _contractor.picturesOfWork.forEach((item) =>
              images.push(...item.images)
            );
          }
          getUserInfo();
          getReviews(id);
          let picOfWorks = _contractor.picturesOfWork.sort((picOfWorkAs) =>
            picOfWorkAs.categoryId === selectedCategoryState?.id ? -1 : 1
          );
          picOfWorks = extractImageUrlsFromPicOfWork(picOfWorks);
          updateContractorDetails({
            ..._contractor,
            picOfWorks: picOfWorks,
            images: picOfWorks,
          });
        }
      });
    } else {
      // toggleLoading(false);
      // history.push("/");
    }
  }, []);

  useEffect(() => {
    getImageGrid(contractorDetails?.images);
  }, [contractorDetails?.images]);

  useEffect(() => {
    const currentFlow = props?.checkout?.payload?.selectedService;
    let servicePayload;
    switch (currentFlow) {
      case "SolidHardwood":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Engineered":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Carpet":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Tiles":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Epoxy":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Laminated":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Other":
        servicePayload = props.buttonReducer.floorPayload;
        break;
      case "Basement":
        servicePayload = props.buttonReducer.basementPayload;
        break;
      case "Wall opening":
        servicePayload = props.buttonReducer.wallPayload;
        break;
      case "Kitchen Remodeling":
        servicePayload = props.buttonReducer.kitchenPayload;
        break;
      case "deck":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "fence":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "interior":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "exterior":
        servicePayload = props.buttonReducer.paintingPayLoad;
        break;
      case "Bathroom renovation":
        servicePayload = props.buttonReducer.bathroomPayload;
        break;
      case "HVAC":
        servicePayload = props.buttonReducer.hvacPayload;
        break;
      case "Plumbing":
        servicePayload = props.buttonReducer.plumbingPayload;
        break;
      case "Roofing":
        servicePayload = props.buttonReducer.roofingPayload;
        break;
      case "Windows":
        servicePayload = props.buttonReducer.windowsPayload;
        break;
      case "Electric":
        servicePayload = props.buttonReducer.electricPayload;
        break;
      case "Tree services":
        servicePayload = props.buttonReducer.treePayload;
        break;
      default:
        break;
    }
    setSelectedServicePayload(servicePayload);
  }, []);

  useEffect(() => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (!user) {
      history.push("/signin");
      confirm("info", "Sign in to continue");
    }
  }, []);

  const imageContainerWidth = viewportWidth - 80;
  return (
    <>
      <Header />
      {!isLoading ? (
        <div className="confirm-contractor">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="d-flex flex-column">
                <div className="protection d-flex flex-row flex-lg-column">
                  <img src={checked} />
                  <div className="">
                    <h6 className="mt-0 mt-lg-auto">
                      {t("confirmContractor.protection")}
                    </h6>
                    <p>{t("confirmContractor.protectionDesc")}</p>
                    <span>{t("confirmContractor.learnMore")}</span>
                  </div>
                </div>
                <div className="details-card-mobile p-3 d-flex d-lg-none mb-4">
                  <div style={{ maxWidth: "40%" }}>
                    <div className="contractor-image-container">
                      <img
                        className="contractor-image"
                        src={profileImage ? profileImage : IMAGES.other.person}
                      />
                    </div>
                  </div>
                  <div className="d-flex ms-3 align-items-start flex-column">
                    <p className="name">{contractorId?.name}</p>
                    <p className="exp">
                      {contractorDetails?.hirings || 0}{" "}
                      {t("contractor.hirings")} <span className="">|</span>{" "}
                      {managerExperience || 0} {t("contractor.experience")}
                    </p>
                    {contractorDetails?.contractorId?.isVerified ? (
                      <div className="d-flex flex-wrap align-items-center w-100 align-self-center">
                        <img
                          height={20}
                          width={20}
                          src={IMAGES.home.verified}
                        />{" "}
                        <p className="verified">{t("contractor.verified")}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {contractorDetails?.images?.length > 0 ? (
                  <div
                    className="d-block d-lg-none mt-3 mb-5"
                    style={{
                      overflowX: "scroll",
                      height: "250px",
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid #E5E5E5",
                      paddingBottom: "30px",
                    }}
                  >
                    {contractorDetails?.images?.map((image) => (
                      <div
                        style={{
                          width: `${imageContainerWidth}px`,
                          display: "inline-block",
                          marginRight: "10px",
                          height: "100%",
                        }}
                      >
                        <img
                          src={image}
                          style={{
                            height: "100%",
                            borderRadius: "20px",
                            width: "100%",
                            objectFit: "cover",
                            // margin: "20px",
                          }}
                          // className="img-fluid"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                <div className="cost">
                  <h6>{t("confirmContractor.costHead")}</h6>
                  <div className="col-12 d-flex justify-content-between">
                    <div className="col-6 cost-label">
                      <p>{t("confirmContractor.projectCost")}</p>
                      <p>{t("confirmContractor.tax")}</p>
                      <p>{t("confirmContractor.serviceFee")}</p>
                      <p>{t("confirmContractor.total")}</p>
                      <h4>{t("confirmContractor.payNow")}</h4>
                    </div>
                    <div className="col-6 cost-value">
                      <p>
                        {`${formatPrice(
                          props?.checkout?.payload?.totalCost
                        )}` || 0}
                      </p>
                      <p>
                        ${" "}
                        {formatPrice(
                          getMaterialTaxFor(selectedServicePayload) || 0
                        )}
                      </p>
                      <p>$ {0}</p>
                      <p>
                        ${" "}
                        {formatPrice(
                          (props?.checkout?.payload?.totalCost || 0) +
                            (getMaterialTaxFor(selectedServicePayload) || 0)
                        )}
                      </p>
                      <h4>$ {99.0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <p className="pay-now-desc">
                    {t("confirmContractor.payNowDesc")}
                  </p>
                </div>
                <div className="col-12 select-address">
                  <div className="d-flex w-100 justify-content-between">
                    <h4>{t("confirmContractor.selectAddress")}</h4>
                    <button
                      className="add-address"
                      onClick={() => {
                        setAddressDetails(null);
                        setAddaddressModal(true);
                      }}
                    >
                      {t("confirmContractor.add")}
                    </button>
                  </div>
                  {addressLoader ? (
                    <Loader progress={addressLoader} />
                  ) : (
                    addresses?.map((address) => (
                      <div
                        className={`address ${
                          selectedAddress?._id === address?._id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => setSelectedAddress(address)}
                      >
                        <img src={IMAGES.home.addressIcon} />
                        <div className="d-flex flex-column justify-content-start">
                          <p className="m-0 p-0">
                            {address?.fullName}: {address?.fullAddress};{" "}
                            {address?.city}, {address?.state}, {address?.zip}
                          </p>
                          <div className="d-flex">
                            <button
                              onClick={(e) => handleEdit(e, address)}
                              className="add-address"
                            >
                              {" "}
                              {t("confirmContractor.edit")}
                            </button>
                            <button
                              onClick={(e) => handleDeleteAddress(address._id)}
                              className="add-address mx-2"
                              style={{ color: "red" }}
                            >
                              {" "}
                              {t("delete.delete")}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <div className="col-12 pricing-info">
                  <input
                    type="checkbox"
                    className="confirm-check"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <p>{t("confirmContractor.pricingInfo")}</p>
                </div>
                <div className="col-12 buttons-container">
                  <ElementsConsumer>
                    {({ stripe }) => (
                      <button
                        onClick={(e) => handleSubmit(e, stripe)}
                        className="confirm"
                        disabled={selectedAddress === "" || !isChecked}
                        type="submit"
                      >
                        {t("confirmContractor.confirm")}
                      </button>
                    )}
                  </ElementsConsumer>

                  <button onClick={onSubmitFormLater} className="grey">
                    {t("confirmContractor.notReady")}
                  </button>

                  <button
                    onClick={() => {
                      window.location = "/";
                    }}
                    className="cancel"
                  >
                    {t("confirmContractor.cancel")}
                  </button>
                </div>
                <div
                  className="col-12"
                  onClick={() => {
                    window.location = "/contact-us";
                  }}
                >
                  <button className="help">
                    {t("confirmContractor.help")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="details-card-primary d-none d-lg-flex">
                <div className="details-card-left">
                  <div className="d-flex">
                    <div className="contractor-image-container">
                      <img
                        className="contractor-img"
                        src={profileImage ? profileImage : IMAGES.other.person}
                      />
                      <img
                        className="checked-image"
                        src={checked}
                        alt="checked"
                      />
                    </div>
                    <div className="d-flex flex-column ps-4 justify-content-center contractor-info">
                      <p className="contractor-name mb-0">
                        {contractorId?.name}
                      </p>
                      <p className="d-flex align-items-center contractor-rating mb-0">
                        <img height={20} width={20} src={Star} /> &nbsp;
                        <span className="me-1">{ratingInfo?.rating || 0} </span>
                        <span className="mb-2 me-1">.</span>
                        <span className="">
                          {ratingInfo?.rating
                            ? getRatingCount(ratingInfo?.rating)
                            : 0}{" "}
                          {t("contractor.reviews")}
                        </span>
                      </p>
                      {contractorDetails?.contractorId?.isVerified ? (
                        <div className="d-flex w-100 justify-content-between align-items-center mt-4">
                          <img className="" src={IMAGES.home.verified} />
                          <p className="verified ms-2">
                            {t("contractor.verified")}
                          </p>{" "}
                          <img src={Info} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex details-card-right flex-column justify-content-center  ${
                    contractorDetails?.contractorId?.isVerified
                      ? "mb-5 mt-2"
                      : "mt-4 mb-0"
                  }`}
                >
                  <p className="text-end mb-0">
                    {ratingInfo?.rating ? ratingInfo?.rating?.toString() : 0}{" "}
                    {t("contractor.hirings")}
                  </p>

                  <p className="text-end mt-2">
                    {managerExperience} {t("contractor.experience")}
                  </p>
                </div>
              </div>
              <div className="row d-none d-lg-block">
                <ConfirmPageImageGrid
                  handleViewMore={handleViewMore}
                  handleIndex={handleIndex}
                  imageArray={contractorDetails?.images}
                  imageRefs={imageRefs}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader progress={isLoading} />
      )}
      <NewFooter loading={isLoading} />
      <CustomDrawer
        getImageGrid={getImageGrid}
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        contractorDetails={contractorDetails}
        currentActive={currentActive}
        imageRefs={imageRefs}
        imageGrid={imageGrid}
      />
      <Modal
        open={sliderOpen}
        onClose={() => {
          setSliderOpen(!sliderOpen);
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="bg-white"
        sx={{ overflowY: "auto" }}
        BackdropProps={{
          style: {
            backgroundColor: "#111",
          },
          onClick: () => {},
        }}
      >
        <div className="local-bootstrap carrousels">
          <div className="d-flex justify-content-between my-2 pb-3 px-3 px-lg-0">
            <span className="text-white text-center w-100">{`${
              currentActive + 1
            } / ${contractorDetails?.images?.length}`}</span>
            <img
              onClick={() => setSliderOpen(false)}
              src={IMAGES.home.modalClose}
            />
          </div>
          <Carousel
            currentActive={currentActive}
            setCurrentActive={setCurrentActive}
          >
            {contractorDetails?.images?.length > 0 &&
              contractorDetails?.images?.map((image, index) => (
                <img
                  className="custom-slider-img object-fill"
                  src={image}
                  ref={imageRefs[index]}
                  style={{ borderRadius: "20px !important" }}
                />
              ))}
          </Carousel>
        </div>
      </Modal>
      <Modal
        open={addAddressModal}
        onClose={() => {
          setAddaddressModal(false);
        }}
        style={{ overflowY: "auto" }}
        sx={{
          outline: "none",
          margin: "auto",
        }}
      >
        <div
          className="mx-2 mx-sm-auto"
          style={{
            maxWidth: "650px",
            margin: "auto",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <div
            className="d-flex flex-column verify-mobile-modal bg-white position-relative"
            style={{ padding: "5%" }}
          >
            <div
              className="position-absolute"
              style={{
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setAddaddressModal(false);
                setAddressDetails(null);
              }}
            >
              <img src={IMAGES.home.closeIcon} />
            </div>
            <AddAddressForm
              setAddaddressModal={setAddaddressModal}
              setAddressLoader={setAddressLoader}
              addressDetails={addressDetails}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  buttonReducer: state.buttonReducer,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(ConfirmContractor));
