import React, { createRef, useEffect, useRef, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import Modal from "@mui/material/Modal";
import Star from "./images/Star.svg";
import Ellipse from "./images/Ellipse.png";
import Heart from "./images/Heart.svg";
import Chat from "./images/Chat.svg";
import HeartFilled from "./images/HeartFilled.svg";
import Share from "./images/Share.svg";
import ReviewCard from "./ReviewCard";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import {
  getContractor,
  getContractorRegisteredAddresses,
  getRatings,
} from "../../services/api/contractor.service";
import Loader from "../../_components/spinner/Loader";

import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import { checkoutStore } from "../../_redux/actions";
import {
  addContractorToWishlist,
  fetchWishlist,
  ownerRegisteredAddresses,
  removeContractFromToWishlist,
} from "../../services/api/owner.service";
import {
  extractImageUrlsFromPicOfWork,
  parseJwt,
} from "../../services/utils/utilFunctions";

import { createChannel } from "../../services/api/chat.service";
import ContractorDetailCard from "./ContractorDetailCard";
import SelectContractorCard from "./SelectContractorCard";
import MapComponent from "./MapComponent";
import "./bootstrap-styles.scss";
import { isEqual } from "lodash";
import useNotify from "../../_components/Notification/UseNotify";
import ImageMapper from "./ImageMapper";
import Header from "../homeComponents/headerSection/Header";
import NewFooter from "../homeComponents/footerSection/NewFooter";
import Carousel, { CarouselItem } from "./custom-carousel/CustomCarousel";
import CustomDrawer from "./CustomDrawer";
import { useTranslation } from "react-i18next";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { useRecoilState } from "recoil";
import {
  selectedCategory,
  subCategories,
} from "../../recoil/atoms/categoryAtom";
import { stepperFormAtom } from "../../recoil/atoms/stepperFormAtom";
import FormFlow from "../stepper-form/formflow";
import Drawer from "@mui/material/Drawer";
import { Box, SwipeableDrawer, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import SolidHardwoodIndex from "../floorComponents/solidHardwood";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import engineeredRoutes from "../floorComponents/engineered/engineeredRoutes";
import { epoxyRoutes } from "../floorComponents/Epoxy/epoxyRoutes";
import { laminatedRoutes } from "../floorComponents/Laminated/laminatedRoutes";
import { tilesRoutes } from "../floorComponents/Tiles/tilesRoutes";
import { carpetRoutes } from "../floorComponents/carpet/carpetRoutes";
import { Bathroomlanding } from "../bathroomComponents/landing";
import { bathroomRoutes } from "../Bathroom/bathroomRoutes";
import { PaintLanding } from "../paintingComponents/landing";
import { paintingRoutes } from "../paintingComponents/paintingRoutes";
import { kitchenRoutes } from "../kitchenComponents/kitchenRoutes";
import { OpeningSize } from "../wallOpening/openingSize";
import { wallOpeningRoutes } from "../wallOpening/wallOpeningRoutes";
import { electricRoutes } from "../Electric/electricRoutes";
import { ElectricTypeofService } from "../Electric/typeOfService";
import { PlumbingTypeofService } from "../Plumbing/typeOfService";
import { plumbingRoutes } from "../Plumbing/plumbingRoutes";
import solidHardWoodRoutes from "../floorComponents/SolidHardwoodNew/solidHardWoodRoutes";
import SolidHardwood from "../floorComponents/solidHardwood/solidHardwood";
import { TypeOfService } from "../basement/TypeOfService";
import { basementRoutes } from "../basement/basementRoutes";
import { TreeTypeOfService } from "../TreeService/TypeOfService";
import { treeServiceRoutes } from "../TreeService/treeServiceRoutes";
import ConfirmPageImageGrid from "./ConfirmPageImageGrid";
import { WindowTypeofService } from "../windows/typeOfService";
import { windowsRoutes } from "../windows/windowsRoutes";
import { RoofingTypeofService } from "../Roofing/typeOfService";
import { roofingRoutes } from "../Roofing/roofingRoutes";
import { hvacRoutes } from "../HVACcomponents/hvacRoutes";
import { HvacTypeofService } from "../HVACcomponents/typeOfService";
import { Dialog } from "@material-ui/core";

const Portfolio = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedCategoryState, setSelectedCategoryState] =
    useRecoilState(selectedCategory);
  const [selectedContractorId, setSelectedContractorId] = useRecoilState(
    selectedContractorAtom
  );
  const [subCategoriesState, setSubCategoriesState] =
    useRecoilState(subCategories);
  const [stepperFormState, setStepperFormState] =
    useRecoilState(stepperFormAtom);

  const [isLoading, toggleLoading] = useState(false);
  const [isLiking, setIsLiking] = useState({ message: "", status: false });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [questionsDrawer, setQuestionsDrawer] = useState(false);
  const reduxState = useSelector((state) => state);
  const checkout = reduxState.checkout;
  const userExists = getData(LOCAL_STORAGE.user) || false;
  const [contractorDetails, updateContractorDetails] = useState({});
  const [addresses, updateAddresses] = useState([]);
  const [index, setIndex] = useState(-1);
  const [reviewsArray, setReviewsArray] = useState([]);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [wishList, setWishList] = useState([]);
  const [isWishListed, setIsWishlisted] = useState(false);
  const [isWhite, setIsWhite] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [resetModal, setResetModal] = useState(false);

  const [selected, setSelected] = useState({});
  const [secondPosition, setSecondPosition] = useState("50%");
  const [currentActive, setCurrentActive] = useState(0);
  const [imageGrid, setImageGrid] = useState();
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const topBarRef = useRef(null);
  const questionsDrawerRef = useRef();
  const drawerBleeding = 56;
  const isMobile = window?.innerWidth < 992;

  // const [imageRefs, setImageRefs] = useState([]);

  const { confirm } = useNotify();
  const imageRefs = useRef([]);

  const pathArray = history.location.pathname.split("/");
  const contractorRealId = pathArray[2];
  setSelectedContractorId(contractorRealId);

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  useEffect(() => {
    setSelected(contractorDetails?.address?.[0]);
    setSubCategoriesState(
      contractorDetails?.services?.filter(
        (service) => service?.categoryId?._id === selectedCategoryState?.id
      )
    );
  }, [contractorDetails]);

  useEffect(() => {
    const pathArray = history.location.pathname.split("/");
    const id = pathArray[2];

    if (!isNaN(parseInt(id))) {
      toggleLoading(true);
      getContractor(id).then((contractor) => {
        if (contractor.data) {
          toggleLoading(false);
          let _contractor = { ...contractor.data };
          const images = [];
          if (_contractor?.picturesOfWork?.length > 0) {
            _contractor.picturesOfWork.forEach((item) =>
              images.push(...item.images)
            );
          }
          getUserInfo();
          getReviews(id);
          getWishList(id);
          const selectedCategoryId =
            checkout?.payload?.selectedServiceDetails?.categoryId;
          let picOfWorks = _contractor.picturesOfWork?.sort((picOfWorkAs) =>
            picOfWorkAs.categoryId === selectedCategoryState?.id ? -1 : 1
          );
          picOfWorks = extractImageUrlsFromPicOfWork(picOfWorks);
          updateContractorDetails({
            ..._contractor,
            picOfWorks: picOfWorks,
            images: picOfWorks,
          });
        }
      });
    } else {
      toggleLoading(false);
      history.push("/");
    }
  }, []);

  useEffect(() => {}, []);
  const getReviews = (contractorId) => {
    getRatings(contractorId).then((response) => {
      setReviewsArray(response?.data?.data);
    });
  };

  const getUserInfo = () => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails?.userType === "owner") {
      getOwnerAddresses(userDetails);
    } else if (userDetails?.userType === "contractor") {
      getContractorAddresses(userDetails);
    }
  };

  const getContractorAddresses = (userDetails) => {
    getContractorRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        updateAddresses(data.data.address);
      }
    });
  };

  const getOwnerAddresses = (userDetails) => {
    ownerRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        const addresses = data.data.address;
        updateAddresses(addresses);
      }
    });
  };

  const getWishList = (id) => {
    fetchWishlist().then((data) => {
      console.log(data);
      if (data.status === 200 && data?.data?.wishlists?.length > 0) {
        const wishlisted = data?.data?.wishlists || [];
        const filtered = wishlisted?.filter(
          (item) => item.contractor?._id == id
        );
        if (filtered?.length > 0) {
          setIsWishlisted(true);
        } else {
          setIsWishlisted(false);
        }
        setWishList(wishlisted);
      } else {
        setIsWishlisted(false);
      }
    });
  };

  const addToWishList = async () => {
    try {
      setIsLiking({ message: "Adding to WishList...", status: true });
      const res = await addContractorToWishlist(contractorRealId);
      if (res.status == 200 && res?.data?.status) {
        getWishList(contractorRealId);
        setIsLiking({ message: "", status: false });
      } else {
        setIsLiking({ message: "", status: false });
        confirm("error", "Something went wrong !!!");
      }
    } catch (err) {
      confirm("error", "Failed to add to wishlist");
      setIsLiking({ message: "", status: false });
    }
  };

  const removeFromWishList = async () => {
    setIsLiking({ message: "Removing from WishList...", status: true });
    try {
      const res = await removeContractFromToWishlist(contractorRealId);
      if (res.status == 200) {
        getWishList(contractorRealId);
        setIsLiking({ message: "", status: false });
      } else {
        setIsLiking({ message: "", status: false });
        confirm("error", "Something went wrong !!!");
      }
    } catch (err) {
      confirm("error", "Failed to remove from wishlist");
      setIsLiking({ message: "", status: false });
    }
  };

  const handleAddorRemoveFromWishlist = async () => {
    if (userExists) {
      if (isWishListed) {
        removeFromWishList();
      } else {
        addToWishList();
      }
    } else {
      history.push("/signin");
      await confirm("error", "Sign in to add contractor to wishlist");
    }
  };

  const copyProfileUrl = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(window?.location);
      await confirm("success", "Profile URL Copied Successfully");
    } else {
      document.execCommand("copy", true, window?.location);
      await confirm("success", "Profile URL Copied Successfully");
    }
  };

  const handleRating = (rating) => {
    let _rating = "";
    let ratingArray = [];

    if (isNaN(parseInt(rating))) _rating = 0;
    else _rating = parseInt(rating);

    let filled = parseInt(_rating);
    let empty = 5 - _rating;

    ratingArray.push(...new Array(filled).fill(1));
    ratingArray.push(...new Array(empty).fill(0));
    return ratingArray;
  };

  const onSelectContractor = (id) => {
    const user = getData(LOCAL_STORAGE.user);
    if (user) {
      setIsClicked(true);
      if (window.screen.width < 993) {
        setQuestionsDrawer(true);
        setStepperFormState({ ...stepperFormState, step: 1 });
      } else {
        // thirdRef.current.style.scrollMarginTop = "200px";
        // thirdRef.current.scrollIntoView({ behaviour: "smooth", block: "start" });
        // secondRef.current.style.maxHeight = "800px";
        // secondRef.current.style.height = "100%";
        // secondRef.current.style.overflowY = "auto";
        // setSecondPosition("0");
        // secondRef.current.style.position = "relative";
        setStepperFormState({ ...stepperFormState, step: 1 });
        const { top } = firstRef.current.getBoundingClientRect();
        console.log(top);
        window.scrollTo(0, window.pageYOffset + top);

        // getServicePriceDetails(selectedCategoryId, )
        // const user = getData(LOCAL_STORAGE.user);
        // if (user) {
        //   const checkoutModel = checkout.payload;
        //   checkoutModel.selectedContractorId = id;
        //   checkoutModel.ownerId = user._id;
        //   checkoutModel.ownerName = user.name;
        //   checkoutModel.ownerImage = IMAGES.home.hdmanSlide;
        //   dispatch(checkoutStore(checkoutModel));
        //   if (addresses.length > 0) {
        //     history.push(`/checkout/payment-method`);
        //   } else {
        //     history.push(`/checkout/enter-address`);
        //   }
        // } else {
        //   alert("Sign in as owner and try again");
        // }
      }
      history.push(`${window.location.pathname}/${selectedCategoryState?.key}`);
    } else {
      confirm("info", "Signin to continue");
      history.push("/signin");
    }
  };

  const getSliderImageData = (images) => {
    // const selectedCategoryId = checkout.payload.selectedServiceDetails.categoryId;
    // let picOfWorks = contractorDetails.picturesOfWork.sort((picOfWorkAs) => picOfWorkAs.categoryId === selectedCategoryId ? -1 : 1);
    // picOfWorks = extractImageUrlsFromPicOfWork(picOfWorks);
    return images.map((img, index) => (
      <CarouselItem key={index}>
        <img
          style={{ borderRadius: "0 !important" }}
          className="object-fill"
          src={img}
        />
      </CarouselItem>
    ));
  };

  const onChatClick = async (contractor) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      history.push("/signin");
      await confirm("error", "Signin to chat with contractor");
    } else {
      const data = {
        contractorID: contractor.contractorId._id,
        ownerID: user._id,
        userType: user.userType,
      };
      if (data.ownerID === user._id) data["userType"] = "owner";
      if (data.contractorID === user._id) data["userType"] = "contractor";

      createChannel(data).then((response) => {
        if (response.status !== 200) {
          console.log("Something went wrong!");
          return;
        }

        history.push({
          pathname: "/user/chat",
          search: `?sid=${response.data}`,
        });
      });
    }
  };

  const handleIndex = (index) => {
    setCurrentActive(index);
    setDrawerOpen(true);
  };

  const projectFrom = JSON.parse(sessionStorage.getItem("whilePlacingOrder"));

  const getFormattedDate = (date) => {
    var dateObj = new Date(date); // yyyy-mm-dd
    var month = dateObj.toLocaleString("default", { month: "long" });
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const handleViewMore = (e) => {
    e.stopPropagation();
    setSliderOpen(true);
    setCurrentActive(0);
  };

  const openSlider = (index) => {
    setCurrentActive(index);
    setSliderOpen(true);
  };

  function getImageGrid(imageUrls) {
    const items = [];

    for (let i = 0; i < imageUrls?.length; i += 3) {
      const item1 = (
        <div key={i} className="image-grid__item--full">
          <img
            onClick={() => openSlider(i)}
            src={imageUrls[i]}
            alt={`Image ${i + 1}`}
            ref={(el) => (imageRefs.current[i] = el)}
            id={i}
          />
        </div>
      );

      let item2 = null;
      let item3 = null;

      if (i + 1 < imageUrls.length) {
        item2 = (
          <div key={i + 1} className="image-grid__item--half">
            <img
              onClick={() => openSlider(i + 1)}
              src={imageUrls[i + 1]}
              alt={`Image ${i + 2}`}
              ref={(el) => (imageRefs.current[i + 1] = el)}
              id={i + 1}
            />
          </div>
        );
      }

      if (i + 2 < imageUrls.length) {
        item3 = (
          <div key={i + 2} className="image-grid__item--half">
            <img
              onClick={() => openSlider(i + 2)}
              src={imageUrls[i + 2]}
              alt={`Image ${i + 3}`}
              ref={(el) => (imageRefs.current[i + 2] = el)}
              id={i + 2}
            />
          </div>
        );
      }

      const row = (
        <div key={i / 3} className="image-grid__row">
          {item1}
          <div className="image-grid__row-half">
            {item2}
            {item3}
          </div>
        </div>
      );

      items.push(row);
    }

    setImageGrid(items);
  }

  useEffect(() => {
    const handleScroll = () => {
      const firstElement = firstRef?.current;
      const secondElement = secondRef?.current;
      const containerHeight = thirdRef?.current?.offsetHeight;
      const firstHeight = firstElement?.offsetHeight;
      const secondHeight = secondElement?.offsetHeight;
      const secondPosition =
        ((firstElement?.offsetTop + firstHeight) / containerHeight) * 100;

      setSecondPosition("0");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      setIsWhite(false);
      const currentScrollPos = window.pageYOffset;
      if (window.scrollY < 100) {
        topBarRef.current.style.backgroundColor = "rgba(255, 255, 255, 0)";
        return;
      }
      if (prevScrollPos < currentScrollPos) {
        if (window.pageYOffset > 0) {
          topBarRef.current.style.backgroundColor = `rgba(255, 255, 255, ${Math.min(
            1,
            window?.pageYOffset / 100
          )})`;
          setIsWhite(true);
        } else {
          setIsWhite(false);
        }
      } else if (prevScrollPos > currentScrollPos) {
        if (window.pageYOffset < 350 && window.pageYOffset > 100) {
          topBarRef.current.style.backgroundColor = `rgba(255, 255, 255, ${Math.min(
            1,
            window?.pageYOffset / 100
          )})`;
          setIsWhite(true);
        } else {
          setIsWhite(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getImageGrid(contractorDetails?.images);
  }, [contractorDetails?.images]);

  useEffect(() => {
    const pathname = history?.location?.pathname?.split("/");
    if (pathname?.length < 4) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  }, [history?.location]);

  return (
    <>
      <Header parentClass="d-none d-lg-block" />
      <div className="d-block d-lg-none local-bootstrap">
        <div
          className={`d-flex d-lg-none top-bar ${isWhite ? "isWhite" : ""}`}
          ref={topBarRef}
        >
          <button onClick={() => history.goBack()}>
            <img src={IMAGES.home.backIcon} />
          </button>
          <div className="d-flex justify-content-start icon-container">
            <button
              onClick={copyProfileUrl}
              className="p-0"
              data-toggle="tooltip"
              data-placement="top"
              title="Copy profile URL"
              style={{ marginRight: "15px" }}
            >
              <img src={IMAGES.home.shareIcon} />
            </button>
            <button
              className="p-0"
              disabled={isLiking?.status}
              data-toggle="tooltip"
              data-placement="top"
              title={
                isWishListed
                  ? "Remove contractor from wishlist"
                  : "Add contractor to wishlist"
              }
              onClick={handleAddorRemoveFromWishlist}
            >
              <img
                src={isWishListed ? HeartFilled : IMAGES.home.favIconWhite}
              />
            </button>
          </div>
          {isLiking?.status ? (
            <span
              className="info-text"
              style={{
                position: "absolute",
                right: "20px",
                top: "auto",
                left: "auto",
                bottom: "-10px",
              }}
            >
              {isLiking?.message}
            </span>
          ) : (
            ""
          )}
        </div>

        {contractorDetails?.images?.length > 0 ? (
          <div
            className="d-block d-lg-none portfolio-carousel"
            style={{ marginBottom: "30px" }}
          >
            {/* <BearCarousel
              className="star_slider"
              key={Math.random()}
              data={getSliderImageData(contractorDetails.images)}
              isEnableLoop
              isEnableNavButton
              isEnablePagination
              aspectRatio={{ widthRatio: 16, heightRatio: 9 }}
            /> */}
            <Carousel
              hideArrows={true}
              hasBorderRadius={false}
              showCount={true}
            >
              {getSliderImageData(contractorDetails?.images)}
            </Carousel>
          </div>
        ) : (
          <div className="py-5 my-5" />
        )}
      </div>
      {Object.values(contractorDetails).length > 0 ? (
        <>
          <div className="porfolio-container local-bootstrap">
            <div className="insideWrp pc-767">
              <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center w-100">
                <h1 className="d-none d-lg-block main-head">
                  {contractorDetails?.highlight}
                </h1>
                <div className="d-none d-lg-flex position-relative">
                  <div className="d-flex justify-content-start icon-container">
                    <button
                      onClick={copyProfileUrl}
                      className="p-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Copy profile URL"
                      style={{ marginRight: "15px" }}
                    >
                      <img src={Share} />
                    </button>
                    <button
                      disabled={isLiking?.status}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        isWishListed
                          ? "Remove contractor from wishlist"
                          : "Add contractor to wishlist"
                      }
                      onClick={handleAddorRemoveFromWishlist}
                      className="p-0"
                    >
                      <img src={isWishListed ? HeartFilled : Heart} />
                    </button>
                  </div>
                  {isLiking?.status ? (
                    <span className="info-text">{isLiking?.message}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="portfolio-ctn">
                <>
                  <div className="d-none d-lg-block">
                    <ImageMapper
                      handleViewMore={handleViewMore}
                      imageArray={contractorDetails?.images}
                      handleIndex={handleIndex}
                      imageRefs={imageRefs}
                    />
                  </div>
                </>
                <h1
                  className="d-block d-lg-none main-head border-bottom"
                  style={{ marginTop: "-50px" }}
                >
                  {contractorDetails?.highlight}
                </h1>
                <div
                  className="contractor-container position-relative mb-0 mb-lg-auto"
                  ref={thirdRef}
                  style={{
                    marginTop:
                      contractorDetails?.images?.length > 0
                        ? isMobile
                          ? "10px"
                          : "50px"
                        : "0",
                  }}
                >
                  <ContractorDetailCard
                    reviewsArray={reviewsArray}
                    contractorDetails={contractorDetails}
                    ref={firstRef}
                    onChatClick={onChatClick}
                  />

                  <SelectContractorCard
                    reviewsArray={reviewsArray}
                    contractorDetails={contractorDetails}
                    onSelectContractor={onSelectContractor}
                    onChatClick={onChatClick}
                    contractorRealId={contractorRealId}
                    secondPosition={secondPosition}
                    ref={secondRef}
                    isClicked={isClicked}
                    className="d-none d-lg-flex"
                  />
                </div>
                {contractorDetails?.images?.length > 0 ? (
                  <div className="d-block d-lg-none mb-5 inspirations">
                    <div className="d-flex justify-content-between w-100 mb-3 mt-0">
                      <h1 className="portfolio-sub-head">
                        {t("contractor.inspirations")}
                      </h1>
                      <button
                        onClick={() => handleIndex(0)}
                        style={{
                          outline: "none",
                          boxShadow: "none",
                        }}
                        className="btn text-info"
                      >
                        See all
                      </button>
                    </div>
                    <ConfirmPageImageGrid
                      handleViewMore={handleViewMore}
                      handleIndex={handleIndex}
                      imageArray={contractorDetails?.images}
                      imageRefs={imageRefs}
                    />
                  </div>
                ) : (
                  ""
                )}
                {reviewsArray.length > 0 && (
                  <div className="reviews">
                    <h1 className="portfolio-sub-head">
                      <span className="d-none d-lg-flex align-items-center">
                        <img className="mb-lg-" src={Star} /> &nbsp;
                        <span>
                          {parseInt(contractorDetails?.ratingInfo?.rating)}
                        </span>
                        &nbsp;
                        <img src={Ellipse} height="5" width="5" />
                        &nbsp;<span>{reviewsArray.length || 0}&nbsp;</span>
                      </span>
                      <span>{t("contractor.reviews")}:</span>
                    </h1>
                    <div className="review-container d-none d-lg-flex">
                      {reviewsArray?.length > 3
                        ? reviewsArray
                            ?.slice(0, 3)
                            ?.map((review) => (
                              <ReviewCard
                                getFormattedDate={getFormattedDate}
                                review={review}
                              />
                            ))
                        : reviewsArray?.map((review) => (
                            <ReviewCard
                              getFormattedDate={getFormattedDate}
                              review={review}
                            />
                          ))}
                    </div>
                    <div className="review-container d-lg-none overflow-x-auto">
                      {reviewsArray?.map((review) => (
                        <ReviewCard
                          getFormattedDate={getFormattedDate}
                          review={review}
                          width={reviewsArray?.length > 1 ? "85%" : "90%"}
                        />
                      ))}
                      {reviewsArray?.length > 3 ? (
                        <div className="d-flex justify-content-center">
                          <button className="show-more-btn">
                            {t("contractor.showAll")} {reviewsArray?.length}{" "}
                            {t("contractor.reviews")}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {reviewsArray?.length > 3 ? (
                      <div className="d-flex justify-content-center d-none d-lg-flex">
                        <button className="show-more-btn">
                          {t("contractor.showAll")} {reviewsArray?.length}{" "}
                          {t("contractor.reviews")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div className="contractor-location">
                  <div className="">
                    <h1 className="portfolio-sub-head">
                      {t("contractor.location")}:
                    </h1>
                    <p className="text-start">{t("contractor.available")}:</p>
                  </div>
                  <div className="d-flex flex-wrap address-buttons ">
                    {contractorDetails?.address?.map((address) => {
                      return (
                        <button
                          onClick={() => setSelected(address)}
                          className={isEqual(selected, address) ? "active" : ""}
                          style={{ borderRadius: "20px", padding: "4px 16px" }}
                        >
                          {address.zip} - {address.city}
                        </button>
                      );
                    })}
                  </div>

                  <div className="map">
                    <MapComponent selected={selected} />
                  </div>
                </div>
                <div className="d-block d-lg-none w-100 mb-4 chat-btn-contnr">
                  <button
                    onClick={() => onChatClick(contractorDetails)}
                    className="chat-btn"
                  >
                    <img src={Chat} />
                    {t("contractor.chat")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={sliderOpen}
            onClose={() => {
              setSliderOpen(!sliderOpen);
            }}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            className="bg-white"
            sx={{ overflowY: "auto", outline: "none" }}
            BackdropProps={{
              style: {
                backgroundColor: "#111",
              },
              onClick: () => {},
            }}
          >
            <div className="local-bootstrap carrousels">
              <div className="d-flex justify-content-between my-2 pb-3 px-3 px-lg-0">
                <span className="text-white text-center w-100">{`${
                  currentActive + 1
                } / ${contractorDetails?.images?.length}`}</span>
                <img
                  onClick={() => setSliderOpen(false)}
                  src={IMAGES.home.modalClose}
                />
              </div>
              <Carousel
                currentActive={currentActive}
                setCurrentActive={setCurrentActive}
              >
                {contractorDetails?.images.length > 0 &&
                  contractorDetails?.images?.map((image) => (
                    <img
                      className="custom-slider-img"
                      src={image}
                      ref={imageRefs[index]}
                    />
                  ))}
              </Carousel>
            </div>
          </Modal>
          <NewFooter loading={isLoading} />

          <CustomDrawer
            getImageGrid={getImageGrid}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
            contractorDetails={contractorDetails}
            currentActive={currentActive}
            imageRefs={imageRefs}
            imageGrid={imageGrid}
          />

          <SwipeableDrawer
            ref={questionsDrawerRef}
            anchor="bottom"
            open={questionsDrawer}
            onClose={() => setQuestionsDrawer(false)}
            sx={{}}
            PaperProps={{
              style: {
                top: "100px",
                borderRadius: "30px",
                overflowY: "auto",
                height: "100%",
                paddingBottom: "100px",
                // position: "relative",
              },
            }}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
          >
            <div
              style={{
                height: "50px",
                backgroundColor: "#fff",
                zIndex: "2000",
              }}
              className="position-sticky top-0 d-flex justify-content-end p-2"
            >
              <div className="d-flex w-50 justify-content-end">
                <Puller />

                <button
                  onClick={() => setQuestionsDrawer(!questionsDrawer)}
                  className="btn"
                >
                  <img src={IMAGES.home.modalClose} />
                </button>
              </div>
            </div>
            <div className="h-100">
              <div
                className="mobile-drawer"
                style={{ padding: "10px", overflowY: "auto" }}
              >
                <Switch>
                  {solidHardWoodRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {engineeredRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {epoxyRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {laminatedRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {tilesRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {carpetRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {bathroomRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {paintingRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {kitchenRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {wallOpeningRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {electricRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {plumbingRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {basementRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {treeServiceRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {windowsRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {roofingRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  {hvacRoutes?.map((route) => (
                    <Route path={route?.path} component={route?.component} />
                  ))}
                  <Route path="/contractor-portfolio/:id/floors">
                    <SolidHardwood />
                  </Route>
                  <Route path="/contractor-portfolio/:id/bathroom">
                    <Bathroomlanding />
                  </Route>
                  <Route path="/contractor-portfolio/:id/painting">
                    <PaintLanding />
                  </Route>
                  <Route path="/contractor-portfolio/:id/wall_opening">
                    <OpeningSize />
                  </Route>
                  <Route path="/contractor-portfolio/:id/electric">
                    <ElectricTypeofService />
                  </Route>
                  <Route path="/contractor-portfolio/:id/plumbing">
                    <PlumbingTypeofService />
                  </Route>
                  <Route path="/contractor-portfolio/:id/basement">
                    <TypeOfService />
                  </Route>
                  <Route path="/contractor-portfolio/:id/tree_services">
                    <TreeTypeOfService />
                  </Route>
                  <Route path="/contractor-portfolio/:id/windows">
                    <WindowTypeofService />
                  </Route>
                  <Route path="/contractor-portfolio/:id/roofing">
                    <RoofingTypeofService />
                  </Route>
                  <Route path="/contractor-portfolio/:id/hvac">
                    <HvacTypeofService />
                  </Route>
                </Switch>
              </div>
            </div>
          </SwipeableDrawer>
          {questionsDrawer ? (
            <div
              className=""
              style={{
                position: "fixed",
                bottom: "0",
                width: "100%",
                zIndex: "2000",
              }}
            >
              <button
                className="w-100 text-center"
                style={{
                  outline: "none",
                  padding: "10px",
                  color: "#2CCAE8",
                  backgroundColor: "#fff",
                  border: "none",
                  borderTop: "1px solid #e5e5e5",
                }}
                onClick={() => setResetModal(true)}
              >
                {t("contractor.resetQuote")}
              </button>
            </div>
          ) : (
            ""
          )}

          <SelectContractorCard
            reviewsArray={reviewsArray}
            contractorDetails={contractorDetails}
            onSelectContractor={onSelectContractor}
            onChatClick={onChatClick}
            contractorRealId={contractorRealId}
            secondPosition={secondPosition}
            ref={secondRef}
            className="d-lg-none"
            showOnMobile
          />
          <Dialog
            onClose={() => setResetModal(false)}
            open={resetModal}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: {
                borderRadius: "20px",
              },
            }}
          >
            <div className="stepper-dialog" style={{}}>
              <img src={IMAGES.home.resetIcon} width="120px" alt="" />
              <div className="stepper-dialog-text" style={{ fontSize: "18px" }}>
                Are you sure want to <br /> reset quote ?
              </div>
              <div className="flex stepper-dialog-footer">
                <div
                  onClick={() => {
                    setResetModal(false);
                  }}
                >
                  No
                </div>
                <div
                  onClick={() => {
                    window.location = `/contractor-portfolio/${selectedContractorId}`;
                  }}
                >
                  Yes
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <>
          <div className="position-relative h-100 d-flex flex-column justify-content-between vh-50 mt-5">
            <Loader progress={isLoading} />
            <NewFooter loading={isLoading} />
          </div>
        </>
      )}
    </>
  );
};

export default Portfolio;
