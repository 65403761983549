import axios from "axios";
import { API_URL, contractorEndPoints } from "../apiConstants";
import { extractImageUrlsFromPicOfWork } from "../utils/utilFunctions";
import { getAxiosInstance } from "../api/config/api.config";
import i18n from "i18next";

export const submitTentativeDate = async (
  contractorId,
  projectId,
  reqBody,
  status
) => {
  if (status === "pending")
    return getAxiosInstance().put(
      `${API_URL}/contractor/${contractorId}/projects/${projectId}`,
      reqBody
    );
};

/**
 *
 * @param {String} contractorId  Id of the contractor
 * @param {String} projectId Id of the project
 * @param {Object} reqBody Data to update
 * @returns Axios instance
 */
export const updateProject = async (contractorId, projectId, reqBody) => {
  return getAxiosInstance().put(
    `${API_URL}/contractor/${contractorId}/projects/${projectId}`,
    reqBody
  );
};

export const updateContractorProfile = async (postData) => {
  try {
    return getAxiosInstance().put(`${API_URL}/contractor/profile`, postData);
  } catch (error) {
    return error;
  }
};

export const confirmProject = async (contractorId, projectId, endPoint) => {
  const response = await getAxiosInstance().put(
    `${API_URL}/contractor/${contractorId}/projects/${projectId}/${endPoint}`
  );
  return response;
};

export const rejectProject = async (contractorId, projectId) => {
  const response = await getAxiosInstance().put(
    `${API_URL}/contractor/${contractorId}/projects/${projectId}/reject`
  );
  return response;
};

export const projectList = async (id, status) => {
  const response = await axios.get(
    `${API_URL}/contractor/${id}/projects/${status}`
  );
  return response;
};

export const getContractors = async (categoryId) => {
  const response = await axios.get(
    `${API_URL}/service-categories/${categoryId}/contractors`,
    { params: { lang: i18n.language } }
  );
  let contractors = [];
  if (response.data) {
    for (let index = 0; index < response.data.length; index++) {
      const con = response.data[index];
      if (con.contractorId && con.contractorId.status === "approve") {
        contractors.push({
          id: con.contractorId._id,
          name: con.contractorId.name,
          images: extractImageUrlsFromPicOfWork(con.picturesOfWork),
          picOfWork: con.picturesOfWork,
          highlight: con.highlight,
          rating: con.ratingInfo ? con.ratingInfo : "N/A",
          portfolioUrl: `/portfolio/${con.contractorId._id}`,
        });
      }
    }
  }
  return contractors;
};

export const getContractorsUsingLocation = async (categoryId, lat, lon) => {
  try {
    const response = await axios.get(
      `${API_URL}/service-categories/${categoryId}/search-contractors?lat=${lat}&lon=${lon}`,
      { params: { lang: i18n.language } }
    );
    let contractors = [];
    if (response && response.data) {
      for (let index = 0; index < response.data.length; index++) {
        const con = response.data[index];
        if (con.contractorId && con.contractorId.status === "approve") {
          contractors.push({
            id: con.contractorId._id,
            name: con.contractorId.name,
            images: extractImageUrlsFromPicOfWork(con.picturesOfWork),
            picOfWork: con.picturesOfWork,
            highlight: con.highlight,
            rating: con.rating ? con.rating : "N/A",
            portfolioUrl: `/portfolio/${con.contractorId._id}`,
          });
        }
      }
    }
    return contractors;
  } catch (err) {
    return err;
  }
};

/**
 * add
 * @param {Object} data data to pass
 * @returns Response
 */
export const getContractor = async (contractorId) => {
  try {
    const authToken = localStorage.getItem("auth_token");
    const config = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: { lang: i18n.language },
    };
    const response = await axios.get(
      `${API_URL}${contractorEndPoints.getContractor}/${contractorId}/profile`,
      config
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};
export const updateOwnerRating = async (
  ratingFor,
  ownerId,
  projectId,
  contractorId,
  rating,
  review
) => {
  const response = await getAxiosInstance().post(`${API_URL}/v1/ratings`, {
    identifier: ratingFor,
    rating: rating,
    review,
    project: projectId,
    owner: ownerId,
    contractor: contractorId,
  });
  return response;
};
export const getRatings = async (contractorId) => {
  const response = await getAxiosInstance().get(
    `${API_URL}/v1/ratings?contractor=${contractorId}`
  );
  return response;
};

export const getContractorProjectById = async (contractorId, projectId) => {
  try {
    const response = await axios.get(
      `${contractorEndPoints.contractor}/${contractorId}/projects/${projectId}/details`,
      { params: { lang: i18n.language } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const removePicOfWorkImage = async (imageId, reqBody) => {
  try {
    const response = await getAxiosInstance().delete(
      `${contractorEndPoints.contractor}/profile/picturesOfWork/${imageId}`,
      { data: reqBody }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Returns the contractor profile
 * @returns AxiosResponse
 */
export const getContractorRegisteredAddresses = async (id) => {
  try {
    const response = await getAxiosInstance().get(
      `${contractorEndPoints.contractor}/${id}/registered-addresses`
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner profile
 * @returns AxiosResponse
 */
export const saveContractorAddress = async (id, data) => {
  try {
    const response = await getAxiosInstance().put(
      `${contractorEndPoints.contractor}/${id}/address`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * add assistant to project
 * @returns AxiosResponse
 */
export const addAssistant = async (contractorId, projectId, data) => {
  try {
    return getAxiosInstance().put(
      `${contractorEndPoints.contractor}/${contractorId}/projects/${projectId}/addAssistant`,
      data
    );
  } catch (error) {
    return error;
  }
};

// import axios from "axios";
// import { API_URL, contractorEndPoints } from "../apiConstants";
// import { extractImageUrlsFromPicOfWork } from "../utils/utilFunctions";

// export const getContractors = async () => {
//   const response = await axios.get(`${API_URL}/admin-portal/contractors`);
//   let contractors = [];
//   if (response.data) {
//     for (let index = 0; index < response.data.length; index++) {
//       const con = response.data[index];
//       // if (
//       //   con.contractorId &&
//       //   con.contractorId.status !== "rejected" &&
//       //   con.picturesOfWork.length > 0
//       // ) {
//         contractors.push({
//           id: '62447abc371011dd6bf44d80',
//           name: 'asdasd',
//           images: [],
//           highlight: 'asdasd',
//           rating: "N/A",
//           portfolioUrl: `/portfolio/62447abc371011dd6bf44d80`,
//         });
//       // }
//     }
//   }
//   return contractors;
// };

// /**
//  * add
//  * @param {Object} data data to pass
//  * @returns Response
//  */
// export const getContractor = async (contractorId) => {
//   try {
//     const authToken = localStorage.getItem("auth_token");
//     const config = {
//       headers: { Authorization: `Bearer ${authToken}` },
//     };
//     const response = await axios.get(
//       `${API_URL}${contractorEndPoints.getContractor}/${contractorId}/profile`,
//       config
//     );
//     return response;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };
// //
