import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { isSupported } from "firebase/messaging";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userPhoneVerify } from "../../../services/api/signin.service";
import { mobileResendOTP } from "../../../services/api/signup.service";
import { siginModel } from "../../../_redux/Models/auth.models";
import { continuetoNext } from "../../../_redux/actions";

import { Button } from "../../../_components/Button/Button";
import { Footer } from "../../../_components/footer/Footer";
import {
  addFirebaseToken,
  deleteFirebaseToken,
} from "../../../services/api/notifications.api.services";
import { FireBase } from "../../../services/firebase/firebase.service";
import Loader from "../../../_components/spinner/Loader";
import IMAGES from "../../../_assets/images";
import { parseJwt } from "../../../services/utils/utilFunctions";

export default function SmsOtpValidator(props) {
  const { history, location } = props;
  const firebase = new FireBase();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isProgress, setIsProgress] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  var firstRef = useRef();
  var secRef = useRef();
  var thirdRef = useRef();
  var fourthRef = useRef();

  if (!location?.state?.mobile) {
    history.push("/signin");
  }
  const { t } = useTranslation();
  const [otp, setOtp] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const deleteToken = async function () {
    const tokenId = localStorage.getItem("tokenId");
    if (tokenId) {
      await deleteFirebaseToken(tokenId);
      localStorage.removeItem("tokenId");
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");

    if (authToken) {
      history.push("/");
    }
  }, []);

  const onChangeHandler = (e) => {
    const newotp = { ...otp };
    newotp[e.target.id] = e.target.value;
    if (newotp["first"].length) {
      secRef.current.focus();
    }
    if (newotp["second"].length) {
      thirdRef.current.focus();
    }
    if (newotp["third"].length) {
      fourthRef.current.focus();
    }
    setOtp(newotp);
  };

  const buttonClick = async () => {
    const verify = {
      mobile: location?.state?.mobile || "",
      otp: otp.first + otp.second + otp.third + otp.fourth,
    };
    if (location?.state?.mobile != undefined) {
      var res = await userPhoneVerify(verify);
      if (res.status == 200) {
        siginModel.userResponse = { ...res?.data };
        dispatch(continuetoNext(siginModel));
        var userToken = parseJwt(res.data?.token);
        localStorage.setItem("authInfo", JSON.stringify({ ...userToken }));
        userToken["originalStatus"] = userToken?.userType;
        localStorage.setItem("user", JSON.stringify({ ...userToken }));
        localStorage.setItem("auth_token", res.data?.token);

        try {
          Notification.requestPermission()
            .then(onPermission)
            .catch((e) => {
              console.error(e);
            });
        } catch (error) {
          if (error instanceof TypeError) {
            Notification.requestPermission(onPermission);
          } else {
            console.log("notification not supprted" + JSON.stringify(error));
          }
        }
        // // Contractors should be redirect to their project section. Not on home page
        const userDetailsParsed = JSON.parse(localStorage.getItem("user"));
        if (userDetailsParsed && userDetailsParsed.userType === "contractor") {
          let redirectUrl = localStorage.getItem("redirectUrl");
          redirectUrl = redirectUrl ? redirectUrl : "/contractor-projects";
          localStorage.removeItem("redirectUrl");
          history.push(redirectUrl);
        } else {
          let redirectUrl = localStorage.getItem("redirectUrl");
          redirectUrl = redirectUrl ? redirectUrl : "/";
          localStorage.removeItem("redirectUrl");
          history.push(redirectUrl);
        }

        setIsProgress(false);
      } else {
        let errorMessage = "Something went wrong!";
        if (res.response && res.response.status > 200) {
          errorMessage = res.response.data.message;
        }
        setIsProgress(false);
        const variant = "error";
        enqueueSnackbar(errorMessage, { variant });
      }
      setIsProgress(false);
    }
  };

  const onPermission = async (permission) => {
    const browserSupport = await isSupported();
    if (!browserSupport) return;

    if (permission === "granted") {
      deleteToken();
      try {
        const token = await firebase.getFirebaseToken();
        window.conversationsClient?.setPushRegistrationId("fcm", token);
        addFirebaseToken(token);
      } catch (e) {
        console.error("firebase not granted", e);
      }
    }
  };

  const phoneResendOTPRequest = () => {
    setIsButtonDisable(true);
    const mobile = location?.state?.mobile ? location?.state?.mobile : "";
    mobileResendOTP({ mobile }).then((response) => {
      setSeconds(30);
      setIsButtonDisable(false);
      const variant = "info";
      const errorMessage = "OTP sent successfully";
      enqueueSnackbar(errorMessage, { variant });
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        setIsButtonDisable(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const backClick = () => {
    history.goBack(`/`);
  };

  return (
    <>
      <div className="pcWidth">
        <Loader progress={isProgress} />
        <div className="signup_center_align">
          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={IMAGES.other.vectors}
                    alt=""
                    onClick={() => backClick()}
                  />
                </td>
                <td>
                  <label
                    className="signup_page_anyhnb_logo"
                    onClick={() => window.location.replace("/")}
                  >
                    {t("signUp.mainHeading")}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div className="signup_flex_with_center signup_validationbox_padding">
            <div
              className={`signup_validation_box ${
                otp.first === "" ? "invalid" : "valid"
              } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                ref={firstRef}
                name="first"
                id="first"
                type="text"
                value={otp.first}
                maxLength={1}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className={`signup_validation_box ${
                otp.second === "" ? "invalid" : "valid"
              } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                type="text"
                ref={secRef}
                name="second"
                id="second"
                value={otp.second}
                maxLength={1}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className={`signup_validation_box ${
                otp.third === "" ? "invalid" : "valid"
              } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                name="third"
                id="third"
                ref={thirdRef}
                type="text"
                value={otp.third}
                maxLength={1}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className={`signup_validation_box ${
                otp.fourth === "" ? "invalid" : "valid"
              } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                name="fourth"
                id="fourth"
                ref={fourthRef}
                type="text"
                value={otp.fourth}
                maxLength={1}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
          </div>
          <div className="signup_flex_with_center signup_validation_not_receive_link_padding">
            <button
              disabled={seconds !== 0 || isButtonDisable}
              onClick={phoneResendOTPRequest}
              style={{ cursor: "pointer" }}
              className="signup_validation_not_receive_link"
            >
              {t("Validation.not_receive")}
            </button>
          </div>
          <small className="signup_flex_with_center timer">
            {seconds > 0 ? `Time remaining: ${seconds}` : ""}
          </small>
          <div className="signup_validation_con_button_padding">
            <Button
              //disabled=={!setOtp}
              disabled={!otp.first || !otp.second || !otp.third || !otp.fourth}
              buttonLabel={t("signIn.continue")}
              onClick={buttonClick}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
